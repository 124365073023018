/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Collapse,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';
import { format } from 'date-fns';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  MdExpandLess,
  MdExpandMore,
  MdOutlineKeyboardArrowDown,
  MdSave,
} from 'react-icons/md';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { searchManifestation } from '../../services/ouvidoria';
import theme from '../../services/theme-service';
import Breadcrumb from './Breadcrumb';

type ParamsProps = {
  codigo: string;
  protocolo: string;
  nome: string;
  email: string;
  cpf: string;
};

export type Manifestation = {
  assunto: {
    id: number;
    titulo: string;
  };
  canal_entrada: string;
  cidade: string;
  conteudo: string;
  envolvidos: [
    {
      cargo: string;
      empresa: string;
      nome: string;
    },
  ];
  estado: string;
  id: number;
  local: string;
  numero_processo: string;
  orgao: {
    nome: string;
    sigla: string;
    slug: string;
  };
  servico_slug: string;
  servico_titulo: string;
  status: {
    anexos_status: [
      {
        id: string;
        titulo: string;
        arquivo: string;
      },
    ];
    descricao: string;
    historico_status: [
      {
        created_at: string;
        descricao: string;
        ordem: number;
        status: string;
        orgao_nome: string;
      },
    ];
    status: string;
    updated_at: string;
  };
  anexos: [
    {
      id: string;
      titulo: string;
      arquivo: string;
    },
  ];
  tipo_identificacao: string;
  tipo_manifestacao: {
    id: number;
    titulo: string;
  };
  vencimento: string;
  updated_at: string;
  created_at: string;
};

const StyledTableCell = withStyles(() => createStyles({
  head: {
    color: '#1F2937',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontFamily: 'Roboto',
    padding: '0px 16px',
    borderBottom: '1px solid #1F2937',
  },
  body: {
    fontSize: theme.typography.pxToRem(14),
    color: '#333333',
  },
}))(TableCell);

export default function ManifestationDetail(): JSX.Element {
  const [expanded, setExpanded] = useState<string | false>(false);
  const { colors } = useContext(colorContext);
  const [manifestationData, setManifestationData] = useState<Manifestation>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<number | null>();

  const useStyles = makeStyles(() => ({
    main: {
      width: '100%',
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
    },
    boxTitle: {
      maxWidth: 1320,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '80px auto 45px auto',
      padding: '0px 10px',
      [theme.breakpoints.down(660)]: {
        flexDirection: 'column',
        gap: 32,
        padding: '0px 16px',
        marginTop: 40,
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(36),
      fontWeight: 800,
      color: '#1F2937',
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
    boxButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 22px 8px 16px',
      border: '1px solid #DAE1EF',
      borderRadius: 4,
      gap: 8,
      cursor: 'pointer',
      [theme.breakpoints.down(660)]: {
        width: '100%',
        justifyContent: 'center',
      },
    },
    titleButton: {
      fontSize: theme.typography.pxToRem(15),
      fontFamily: 'Roboto',
      fontWeight: 500,
      color: '#1F2937',
      textTransform: 'uppercase',
    },
    boxAccordion: {
      width: '100%',
      margin: '24px 32px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    accordionDetail: {
      padding: '0px 42px 16px 16px',
      wordBreak: 'break-word',
      backgroundColor: colors.colorBackgroundSecundary,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 16,
      },
    },
    accordion: {
      border: '1px solid #DAE1EF',
      marginBottom: 20,
      width: '100%',
      boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
    },
    accordionRoot: {
      minHeight: 62,
      backgroundColor: '#F6F8FC',
      borderBottom: '1px solid #DAE1EF',
    },
    accordionIcon: {
      width: 32,
      height: 32,
      color: '#1F2937',
      [theme.breakpoints.down(660)]: {
        width: 24,
        height: 24,
      },
    },
    boxContent: {
      margin: '48px 32px 43px 32px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        margin: '20px 43px 32px 4px',
      },
    },
    titleAccordion: {
      fontFamily: 'Roboto',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      color: '#1F2937',
      marginLeft: 32,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(18),
        marginLeft: 0,
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 7,
    },
    file: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },
    value: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: '#1F2937',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    titleInfo: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      color: '#1F2937',
      marginBottom: 7,
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      color: '#1F2937',
      marginBottom: 10,
    },
    textInfo: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 700,
      color: '#1F2937',
    },
    status: {
      fontSize: 14,
      fontFamily: 'Roboto',
      fontWeight: 500,
      textTransform: 'capitalize',
      borderRadius: 25,
      border: '1px solid rgba(55, 63, 65, 0.05)',
      background: 'rgba(55, 63, 65, 0.05)',
      padding: '5px 16px',
    },
    historyItem: {
      color: '#1F2937',
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 700,
    },
    historyValue: {
      color: '#1F2937',
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontWeight: 500,
      fontFamily: 'Roboto',
      [theme.breakpoints.down(660)]: {
        width: '100%',
        padding: '12px 0px',
      },
      '&:hover': {
        color: colors.textAccentColor,
        backgroundColor: colors.accentColorBackground,
      },
    },
    forwardButton: {
      color: colors.textAccentColor,
      backgroundColor: colors.accentColorBackground,
    },
    container: {
      width: '100%',
      maxWidth: 1320,
      marginBottom: 50,
      padding: '0px 16px',
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const { codigo, protocolo }: ParamsProps = useParams();
  const { state } = useLocation<any>();
  const matches = useMediaQuery('(max-width: 660px)');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeStatusColor = (status: string) => {
    switch (status) {
      case 'Aguardando':
        return '#FAAD13';
      case 'Encaminhado':
        return colors.accentColor;
      case 'Recebido':
        return colors.accentColor;
      case 'Respondido':
        return colors.buttonSuccessColor;
      case 'Arquivado':
        return colors.buttonErrorColor;
    }
  };

  const expandTable = useCallback(
    (index: number) => {
      if (showDetails === index) {
        setShowDetails(null);
      } else {
        setShowDetails(index);
      }
    },
    [showDetails],
  );

  useEffect(() => {
    async function getManifestationData() {
      try {
        setLoading(true);
        const { data } = await searchManifestation(
          codigo,
          protocolo,
          state?.email,
          state?.cpf,
        );
        setManifestationData(data[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (codigo && protocolo) {
      getManifestationData();
    }
  }, [codigo, protocolo]);

  return (
    <Box className={classes.main}>
      <Breadcrumb slug="Detalhes da Manifestação" />
      <Box className={classes.boxTitle}>
        <Typography className={classes.title}>
          Detalhes da Manifestação
        </Typography>
        {/* <Box
          className={classes.boxButton}
          onClick={() => history.push(
            `/detalhes_manifestacao/${codigo}/${protocolo}/imprimir`,
            {
              email: state?.email,
              cpf: state?.cpf,
            },
          )}
        >
          <MdSave size="18px" />
          <Typography className={classes.titleButton}>Imprimir tela de Visualização da manifestação anônima</Typography>
        </Box> */}
      </Box>
      {loading && <CircularProgress />}
      {!loading && manifestationData && (
        <Box
          width="100%"
          maxWidth="1320px"
          marginBottom="20px"
          padding="0px 16px"
        >
          <Accordion
            defaultExpanded
            className={classes.accordion}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              classes={{ root: classes.accordionRoot }}
              expandIcon={
                expanded === 'panel1' ? (
                  <MdExpandMore className={classes.accordionIcon} />
                ) : (
                  <MdExpandMore className={classes.accordionIcon} />
                )
              }
            >
              <Typography className={classes.titleAccordion}>
                Dados Gerais da Manifestação
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              <Box className={classes.boxContent}>
                <Grid container spacing={matches ? 3 : 6}>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>Protocolo</Typography>
                    <span className={classes.value}>
                      {manifestationData?.numero_processo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Código de Acesso
                    </Typography>
                    <span className={classes.value}>{codigo}</span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Tipo de Manifestação
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.tipo_manifestacao?.titulo
                        || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Tipo de Identificação
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.tipo_identificacao
                        || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Órgão Destinatário
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.orgao?.nome || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>Serviço</Typography>
                    <span className={classes.value}>
                      {manifestationData?.servico_titulo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>Assunto</Typography>
                    <span className={classes.value}>
                      {manifestationData?.assunto?.titulo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Canal de entrada
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.canal_entrada || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Local do Fato
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.local || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Data de Registro
                    </Typography>
                    <span className={classes.value}>
                      {format(
                        new Date(manifestationData?.created_at),
                        'dd/MM/yyyy',
                      ) || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Prazo de Resposta
                    </Typography>
                    <span className={classes.value}>
                      {format(
                        new Date(manifestationData?.vencimento),
                        'dd/MM/yyyy',
                      ) || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>Situação</Typography>
                    <span className={classes.value}>
                      {manifestationData?.status?.status || 'Sem resultados.'}
                    </span>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded
            className={classes.accordion}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              classes={{ root: classes.accordionRoot }}
              expandIcon={
                expanded === 'panel2' ? (
                  <MdExpandMore className={classes.accordionIcon} />
                ) : (
                  <MdOutlineKeyboardArrowDown
                    className={classes.accordionIcon}
                  />
                )
              }
            >
              <Typography className={classes.titleAccordion}>
                Teor da Manifestação
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              <Box className={classes.boxContent}>
                <Grid
                  container
                  spacing={matches ? 3 : 6}
                  direction={matches ? 'row' : 'column'}
                >
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>
                      Texto da Manifestação
                    </Typography>
                    <span className={classes.value}>
                      {manifestationData?.conteudo || 'Sem resultados.'}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>Anexos</Typography>
                    {manifestationData?.anexos.length > 0 ? (
                      <Box display="flex" flexDirection="column" gridGap="10px">
                        {manifestationData?.anexos.map((anexo) => (
                          <a
                            className={classes.value}
                            key={anexo.id}
                            href={anexo.arquivo}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {anexo.titulo}
                          </a>
                        ))}
                      </Box>
                    ) : (
                      <span className={classes.value}>Sem anexos</span>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded
            className={classes.accordion}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              classes={{ root: classes.accordionRoot }}
              expandIcon={
                expanded === 'panel3' ? (
                  <MdExpandMore className={classes.accordionIcon} />
                ) : (
                  <MdOutlineKeyboardArrowDown
                    className={classes.accordionIcon}
                  />
                )
              }
            >
              <Typography className={classes.titleAccordion}>
                Histórico da Manifestação
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              <Box className={classes.boxAccordion}>
                {matches ? (
                  <>
                    {manifestationData?.status?.historico_status?.map(
                      (historico, index) => (
                        <>
                          <Grid
                            container
                            key={`${historico.ordem}-key`}
                            spacing={3}
                            style={{
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                          >
                            <Grid item xs={4} className={classes.historyItem}>
                              Ordem
                            </Grid>
                            <Grid item xs={8} className={classes.historyValue}>
                              {historico.ordem}
                            </Grid>
                            <Grid item xs={4} className={classes.historyItem}>
                              Data/Hora
                            </Grid>
                            <Grid item xs={8} className={classes.historyValue}>
                              {`${format(
                                new Date(historico.created_at),
                                'dd/MM/yyyy',
                              )} às ${format(
                                new Date(historico.created_at),
                                'HH:mm',
                              )}`}
                            </Grid>
                            <Grid item xs={4} className={classes.historyItem}>
                              Órgão
                            </Grid>
                            <Grid item xs={8} className={classes.historyValue}>
                              {historico.orgao_nome}
                            </Grid>
                            <Grid item xs={4} className={classes.historyItem}>
                              Status
                            </Grid>
                            <Grid item xs={8} className={classes.historyValue}>
                              <span
                                className={classes.status}
                                style={{
                                  color: handleChangeStatusColor(
                                    historico.status,
                                  ),
                                }}
                              >
                                {historico.status}
                              </span>
                            </Grid>
                            <Grid item xs={4} className={classes.historyItem}>
                              Descrição
                            </Grid>
                            <Grid item xs={8} className={classes.historyValue}>
                              {historico.descricao || 'Sem resultados.'}
                            </Grid>
                            {historico.status === 'Respondido'
                              && manifestationData?.status?.anexos_status.length
                                > 0 && (
                                <>
                                  <Grid
                                    item
                                    xs={4}
                                    className={classes.historyItem}
                                  >
                                    Anexos
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    className={classes.historyItem}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      gap: 5,
                                      flexWrap: 'wrap',
                                    }}
                                  >
                                    {manifestationData?.status?.anexos_status.map(
                                      (anexo) => (
                                        <a
                                          href={anexo.arquivo}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className={classes.value}
                                        >
                                          {anexo.titulo}
                                        </a>
                                      ),
                                    )}
                                  </Grid>
                                </>
                            )}
                          </Grid>
                          {index
                            <= manifestationData?.status?.historico_status
                              .length && <Divider />}
                        </>
                      ),
                    )}
                  </>
                ) : (
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Ordem</StyledTableCell>
                          <StyledTableCell align="left">
                            Data/Hora
                          </StyledTableCell>
                          <StyledTableCell align="left">Órgão</StyledTableCell>
                          {/* <StyledTableCell align="left">Emitido</StyledTableCell> */}
                          <StyledTableCell align="left">Status</StyledTableCell>
                          <StyledTableCell align="right" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {manifestationData?.status?.historico_status?.map(
                          (historico, index) => (
                            <Fragment key={`${historico.ordem}-key`}>
                              <TableRow>
                                <StyledTableCell align="left">
                                  {historico.ordem}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {`${format(
                                    new Date(historico.created_at),
                                    'dd/MM/yyyy',
                                  )} às ${format(
                                    new Date(historico.created_at),
                                    'HH:mm',
                                  )}`}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {historico.orgao_nome ?? 'Teste'}
                                </StyledTableCell>
                                {/* <StyledTableCell align="left">
                            {manifestationData?.}
                          </StyledTableCell> */}
                                <StyledTableCell align="left">
                                  <span
                                    className={classes.status}
                                    style={{
                                      color: handleChangeStatusColor(
                                        historico.status,
                                      ),
                                    }}
                                  >
                                    {historico.status}
                                  </span>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => expandTable(index)}
                                  >
                                    {showDetails === index ? (
                                      <MdExpandLess />
                                    ) : (
                                      <MdExpandMore />
                                    )}
                                  </IconButton>
                                </StyledTableCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={6}
                                >
                                  <Collapse
                                    in={index === showDetails}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box margin="10px 0px">
                                      <Typography className={classes.titleInfo}>
                                        Descrição
                                      </Typography>
                                      <Typography
                                        className={classes.description}
                                      >
                                        {historico.descricao
                                          || 'Sem resultados.'}
                                      </Typography>
                                      {historico.status === 'Respondido'
                                        && manifestationData?.status?.anexos_status
                                          .length > 0 && (
                                          <>
                                            <Typography
                                              className={classes.titleInfo}
                                            >
                                              Anexos
                                            </Typography>
                                            <Box
                                              display="flex"
                                              flexDirection="column"
                                              alignItems="flex-start"
                                              style={{
                                                gap: 10,
                                              }}
                                            >
                                              {manifestationData?.status?.anexos_status.map(
                                                (anexo) => (
                                                  <a
                                                    href={anexo.arquivo}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={classes.value}
                                                  >
                                                    {anexo.titulo}
                                                  </a>
                                                ),
                                              )}
                                            </Box>
                                          </>
                                      )}
                                    </Box>
                                  </Collapse>
                                </StyledTableCell>
                              </TableRow>
                            </Fragment>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      {!loading && !manifestationData && (
        <Typography className={classes.textInfo}>Sem resultados!</Typography>
      )}
      {!loading && (
        <Box className={classes.container}>
          <Button
            onClick={() => history.push('/ouvidoria')}
            className={`${classes.button} ${classes.forwardButton}`}
          >
            Voltar
          </Button>
        </Box>
      )}
    </Box>
  );
}
