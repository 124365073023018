/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import {
  Box, Typography, useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SearchI } from '../../../SearchService';
import colorContext from '../../../../Context/colorContext';

interface Props {
  slug: string;
  service: SearchI;
}

export default function Title({
  slug,
  service,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 200,
      height: 'auto',
      backgroundColor: colors.cardAccentColor,
      marginTop: 1,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(660)]:{
        minHeight: 180,
      }
    },
    container: {
      color: colors.accentColor,
      maxWidth: '1320px',
      margin: '1px auto',
    },
    boxInfoService: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px',
      [theme.breakpoints.down(660)]: {
        padding: '0px 20px',
        alignItems: 'center',
      }
    },
    titleService: {
      fontSize: theme.typography.pxToRem(32),
      fontFamily: 'Roboto',
      fontWeight: 700,
      color: colors.textAccentColor,
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(24),
      }
    },
    subTitleService: {
      fontSize: theme.typography.pxToRem(24),
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.textAccentColor,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down(660)]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(16),
      }
    },
    subBoxInfoService: {
      width: '100%',
      maxWidth: 600,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '20px',
      [theme.breakpoints.down(660)]: {
        justifyContent: 'center',
      }
    },
  }));
  const classes = useStyles();
  const matchesMobile = useMediaQuery('(max-width:660px)');

  return (
    <>
      <Box className={classes.main}>
        <Box
          display="flex"
          maxWidth="100%"
          alignItems="center"
          width="100%"
          justifyContent="center"
          flexDirection="column"
          className={classes.container}
        >
          <Box className={classes.boxInfoService}>
            <Typography
              className={classes.titleService}
            >
              {slug}
            </Typography>
            <Box className={classes.subBoxInfoService}>
              <span
                className={classes.subTitleService}
                style={{ marginBottom: matchesMobile ? '10px' : '0px' }}
              >
                {service.orgao_nome}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
