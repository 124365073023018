import React, { useContext, useMemo } from 'react';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  FaFacebook, FaInstagram, FaTwitter, FaYoutube,
} from 'react-icons/fa';
import colorContext from '../../Context/colorContext';

export default function Licence(): JSX.Element {
  const matchesMobile = useMediaQuery('(max-width: 660px)');
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.accentColorBackground,
      color: colors.textBody,
      padding: matchesMobile ? '0px 20px' : '0px',
    },
    container: {
      display: 'flex',
      maxWidth: '1320px',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    title: {
      fontSize: matchesMobile ? theme.typography.pxToRem(14) : theme.typography.pxToRem(16),
      fontWeight: 400,
      color: colors.textAccentColor,
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
      lineHeight: '1.5rem',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        maxWidth: 295,
        margin: '2.5rem 0px',
        textAlign: 'center',
      },
    },
    nameCompany: {
      fontSize: theme.typography.pxToRem(14),
    },
    boxSocialMedia: {
      display: 'flex',
      flexDirection: matchesMobile ? 'column' : 'row',
      alignItems: 'center',
      gap: 9,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
    titleBoxSocialMedia: {
      fontSize: theme.typography.pxToRem(18),
      color: colors.textAccentColor,
      fontWeight: 400,
    },
    boxSocialMediaItems: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    btnFacebook: {
      color: colors.textAccentColor,
      padding: 0,
      width: 40,
      height: 40,
    },
    btnSocial: {
      backgroundColor: colors.colorBackgroundSecundary,
      color: colors.accentColor,
      padding: 0,
      width: 40,
      height: 40,
      '&:hover': {
        backgroundColor: colors.colorBackgroundSecundary,
      },
    },
    iconSocial: {
      width: 22,
      height: 22,
    },
  }));
  const classes = useStyles();

  const linkFace = process.env.REACT_APP_LINK_FACEBOOK ?? '';
  const linkInsta = process.env.REACT_APP_LINK_INSTAGRAM ?? '';
  const linkYoutube = process.env.REACT_APP_LINK_YOUTUBE ?? '';
  const linkTwitter = process.env.REACT_APP_LINK_TWITTER ?? '';

  const currentYear = useMemo(() => new Date().getFullYear().toString(), []);

  const goToPage = (link: string) => {
    (window as any).open(link);
  };

  return (
    <Box className={classes.main}>
      <Box
        className={classes.container}
      >
        <Typography
          className={classes.title}
        >
          {`${currentYear} ${process.env.REACT_APP_LICENCE}`}
        </Typography>
        <Box
          className={classes.boxSocialMedia}
        >
          <Typography className={classes.titleBoxSocialMedia}>Siga a prefeitura: </Typography>
          <Box className={classes.boxSocialMediaItems}>
            <IconButton
              onClick={(): void => {
                goToPage(linkFace);
              }}
              className={classes.btnFacebook}
            >
              <FaFacebook style={{ width: '100%', height: '100%' }} />
            </IconButton>
            <IconButton
              onClick={(): void => {
                goToPage(linkInsta);
              }}
              className={classes.btnSocial}
            >
              <FaInstagram className={classes.iconSocial} />
            </IconButton>
            <IconButton
              onClick={(): void => {
                goToPage(linkTwitter);
              }}
              className={classes.btnSocial}
            >
              <FaTwitter className={classes.iconSocial} />
            </IconButton>
            <IconButton
              onClick={(): void => {
                goToPage(linkYoutube);
              }}
              className={classes.btnSocial}
            >
              <FaYoutube className={classes.iconSocial} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
