import React, { useContext, useEffect, useState } from 'react';
import {
  Box, CircularProgress,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { apisiseci } from '../../services/api';
import { SearchI } from '../SearchService';
import {
  Breadcrumb,
  ServiceDetais,
  Title,
  ChannelAttendante,
} from './components';
import { getService } from '../../services/servico';
import colorContext from '../../Context/colorContext';

interface Params {
  slug: string;
  modal?: string;
}
export interface ChannelAttendanteI {
  icone: string;
  titulo: string;
  url: string;
}

export default function ServiceIntegration(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 400,
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));
  const classes = useStyles();
  const params: Params = useParams();
  const [service, setService] = useState < SearchI >();
  const [loading, setLoading] = useState < boolean >(false);
  const [channels, setChannels] = useState<ChannelAttendanteI[]>([]);

  const getChannelAttendances = async () => {
    const { data } = await apisiseci.get('/cms/canais_atendimento/');
    setChannels(data);
  };

  const getServiceEspecific = async () => {
    setService(undefined);
    setLoading(true);

    try {
      const { data } = await getService(params.slug);
      setService(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getServiceEspecific();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    getChannelAttendances();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.main}>
      {loading && (
        <Box
          marginBottom="60px"
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {service && (
        <>
          <Breadcrumb service={service} slug={service.titulo} />
          <Title
            slug={service.titulo}
            service={service}
          />
          <ServiceDetais service={service} />
          <ChannelAttendante listChannel={channels} />
        </>
      )}
    </Box>
  );
}
