/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-case */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Radio,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  faUserCheck,
  faUserLock,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-cycle
import { EsicData } from '../..';
import { loginRedirectPrivider } from '../../../../services/auth';
import getCookie from '../../../../utils/getCookies';

interface IdentificationProps {
  controlStep: (action: 'prev' | 'next') => void;
  changeEsicData: (data: EsicData) => void;
}

export default function Identification({
  controlStep,
  changeEsicData,
}: IdentificationProps): JSX.Element {
  const useStyles = makeStyles((theme: Theme) => ({
    boxStepContent: {
      padding: '0px 8px',
      marginTop: 48,
    },
    actionTitle: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 500,
      textAlign: 'center',
      color: '#333',
      [theme.breakpoints.down(660)]: {
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(18),
      },
    },
    boxCards: {
      marginTop: 48,
      display: 'flex',
      gap: 16,
      marginBottom: 80,
      [theme.breakpoints.down(660)]: {
        marginTop: 32,
        padding: '0px 10px',
        marginBottom: 50,
      },
    },
    card: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #DAE1EF',
      borderRadius: 4,
      padding: '32px',
      cursor: 'pointer',
      backgroundColor: '#fff',
      [theme.breakpoints.down(660)]: {
        padding: '22px 22px 32px 22px',
      },
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cardChecked: {
      borderColor: theme.palette.primary.main,
    },
    radioChecked: {
      color: theme.palette.primary.main,
    },
    radioInfo: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 16,
    },
    title: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 600,
      display: 'block',
      marginBottom: 11,
      color: '#333',
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      display: 'block',
      color: '#4F5766',
      lineHeight: '24px',
      maxWidth: 690,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(14),
        maxWidth: '350px',
      },
    },
    icon: {
      width: 32,
      height: 32,
      color: '#DAE1EF',
      [theme.breakpoints.down(660)]: {
        width: 24,
        height: 24,
      },
    },
    iconChecked: {
      color: theme.palette.primary.main,
    },
    boxButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 24,
      paddingBottom: '56px',
      [theme.breakpoints.down(500)]: {
        padding: '0px 10px 40px 10px',
        flexDirection: 'column',
        gap: 30,
      },
    },
    warning: {
      fontSize: theme.typography.pxToRem(14),
      color: '#4F5766',
      fontWeight: 400,
    },
    button: {
      padding: '12px 43px',
      border: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      fontFamily: 'Roboto',
      fontWeight: 500,
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
    },
    boxQuery: {
      maxWidth: 1320,
      width: '100%',
      margin: '40px auto 0px auto',
      paddingBottom: '56px',
      padding: '0px 10px',
    },
    queryTitle: {
      fontSize: theme.typography.pxToRem(24),
      color: '#333',
      fontWeight: 700,
      marginBottom: 16,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(18),
        textAlign: 'center',
      },
    },
    queryDescription: {
      fontSize: theme.typography.pxToRem(16),
      color: '#333',
      fontWeight: 500,

      '&:last-child': {
        marginTop: 10,
      },

      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(15),
        textAlign: 'center',
      },
    },
    queryLink: {
      fontWeight: 500,
      color: '#333',
    },
    error: {
      color: '#F03D3D',
    },
    helperText: {
      marginTop: 5,
      marginLeft: 5,
      color: '#333',
    },
    colorInputText: {
      '&$cssFocused $notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover $notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
    textLabel: {
      color: '#333',
    },
    cssLabel: {
      color: '#333',
    },
    cssFocused: {
      color: '#333',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary} !important`,
      },
    },
    notchedOutline: {
      borderWidth: '1px',
    },
  }));
  const classes = useStyles();
  const location = useLocation();

  const userData = getCookie('gov_user_data') || '';
  const token: string | null = getCookie('gov_access_token_sso');

  const [user, setUser] = useState<'Identificado' | 'Sigiloso'>(
    null,
  );
  const [disabled, setDisabled] = useState(true);

  const controlRadio = (type: 'Identificado' | 'Sigiloso') => {
    setUser(type);
  };

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  const confirmation = () => {
    switch (user) {
      case 'Identificado':
        if (userData && token) {
          changeEsicData({
            tipo_identificacao: 'Identificado',
          });
          controlStep('next');
          break;
        } else {
          loginRedirect();
          break;
        }
      case 'Sigiloso':
        if (userData && token) {
          changeEsicData({
            tipo_identificacao: 'Sigiloso',
          });
          controlStep('next');
          break;
        } else {
          loginRedirect();
          break;
        }
    }
  };

  useEffect(() => {
    if (user !== null) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [user]);

  return (
    <Box className={classes.boxStepContent}>
      <Typography className={classes.actionTitle}>
        Qual tipo de identificação deseja utilizar?
      </Typography>
      <Grid container className={classes.boxCards}>
        <Grid item xs={12}>
          <Box
            className={`${
              user === 'Identificado'
                ? `${classes.card} ${classes.cardChecked}`
                : classes.card
            }`}
            onClick={() => controlRadio('Identificado')}
          >
            <Box className={classes.cardHeader}>
              <Box className={classes.radioInfo}>
                <FontAwesomeIcon
                  className={`${
                    user === 'Identificado'
                      ? `${classes.icon} ${classes.iconChecked}`
                      : classes.icon
                  }`}
                  icon={faUserCheck}
                />
                <Box>
                  <Typography className={classes.title}>
                    Identificado
                  </Typography>
                  <span className={classes.description}>
                    Utilizando o login único (solução própria do gov.br).
                  </span>
                </Box>
              </Box>
              <Radio
                color="default"
                checked={user === 'Identificado'}
                onChange={() => controlRadio('Identificado')}
                value="user"
                name="radio-button"
                classes={{ checked: classes.radioChecked }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            className={`${
              user === 'Sigiloso'
                ? `${classes.card} ${classes.cardChecked}`
                : classes.card
            }`}
            onClick={() => controlRadio('Sigiloso')}
          >
            <Box className={classes.cardHeader}>
              <Box className={classes.radioInfo}>
                <FontAwesomeIcon
                  className={`${
                    user === 'Sigiloso'
                      ? `${classes.icon} ${classes.iconChecked}`
                      : classes.icon
                  }`}
                  icon={faUserLock}
                />
                <Box>
                  <Typography className={classes.title}>Sigiloso</Typography>
                  <span className={classes.description}>
                    Utilizando o login único (solução própria do gov.br), mas seus dados são criptografados.
                  </span>
                </Box>
              </Box>
              <Radio
                color="default"
                checked={user === 'Sigiloso'}
                onChange={() => controlRadio('Sigiloso')}
                value="Anônimo"
                name="radio-button"
                classes={{ checked: classes.radioChecked }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.boxButton}>
        <span className={classes.warning}>
          * Campo de preenchimento obrigatório
        </span>
        <button
          type="button"
          onClick={confirmation}
          className={classes.button}
          disabled={disabled}
          style={{
            opacity: disabled ? '0.7' : '1',
          }}
        >
          Avançar
        </button>
      </Box>
    </Box>
  );
}
