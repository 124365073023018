import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import {
  Fab,
  Theme,
  useScrollTrigger,
  Zoom,
} from '@material-ui/core';
import { IoArrowUp } from 'react-icons/io5';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Footer from '../../Footer';
import Header from '../../Header';
import Licence from '../../Licence';
import { sendCodeForSISECI } from '../../../services/auth';
import RefContext from '../../../Context/refContext';

interface Props {
  children: JSX.Element;
  // eslint-disable-next-line react/require-default-props
  window?: () => Window;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(1),
  },
}));

function ScrollTop(props: Props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default function Dash(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get('code');
    if (code) {
      document.cookie = `pathname_redirect_gov=${encodeURIComponent(pathname)};path=/`;
      sendCodeForSISECI(code, history);
    }
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA}`;
    script.addEventListener('load', () => {});
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToRef = (ref: React.MutableRefObject<any>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  };

  const refCategory = useRef(null);
  const refService = useRef(null);
  const refEstatistic = useRef(null);
  const refAttendance = useRef(null);
  const refNoticias = useRef(null);
  const refInicio = useRef(null);
  const refVideos = useRef(null);
  const refRegistryType = useRef(null);

  const [perfilUser, setPerfilUser] = useState(null);

  return (
    <div>
      <RefContext.Provider value={{
        refCategory, refService, refEstatistic, refAttendance, refNoticias, refInicio, refVideos, scrollToRef, refRegistryType, perfilUser,
      }}
      >
        <Header setPerfilUser={setPerfilUser} />
        {children}
        <Footer />
        <Licence />
        <ScrollTop {...props}>
          <Fab
            style={{
              right: 6, borderRadius: '50%', border: '1px solid #fff',
            }}
            size="large"
            color="primary"
            aria-label="scroll back to top"
          >
            <IoArrowUp style={{ color: '#fff', width: 24, height: 24 }} />
          </Fab>
        </ScrollTop>
      </RefContext.Provider>
    </div>
  );
}
