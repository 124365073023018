/* eslint-disable max-len */
import { AxiosResponse } from 'axios';
import { apiouvidoria } from './api';

export const getOrgansList = (): Promise<AxiosResponse> => apiouvidoria.get('orgaos/');

export const getManifestationTypes = (): Promise<AxiosResponse> => apiouvidoria.get('tipos/');

export const getManifestationTypeBySlug = (
  slug: string,
): Promise<AxiosResponse> => apiouvidoria.get('/tipos', {
  params: {
    slug,
  },
});

export const getListSubjects = (id: number): Promise<AxiosResponse> => apiouvidoria.get('assuntos/', {
  params: {
    tipo_manifestacao: id,
  },
});

export const getListCities = (
  estado: string,
  page: number,
): Promise<AxiosResponse> => apiouvidoria.get('cidades/', {
  params: {
    estado,
    page,
  },
});

export const registerAnonymousManifestation = (
  codigo_anonimo: string,
  conteudo: string,
  tipo_identificacao: string,
  canal_entrada: string,
  servico_slug: string,
  servico_titulo: string,
  setor_id: number,
  estado: string,
  cidade: string,
  local: string,
  envolvidos: any[] | null,
  tipo_manifestacao: number,
  orgao: string,
  assunto: number,
): Promise<AxiosResponse> => apiouvidoria.post('/manifestacao/', {
  codigo_anonimo,
  conteudo,
  tipo_identificacao,
  canal_entrada,
  servico_slug,
  servico_titulo,
  setor_id,
  estado,
  cidade,
  local,
  envolvidos,
  tipo_manifestacao,
  orgao,
  assunto,
});

export const registerIdentifiedManifestation = (
  token: string,
  conteudo: string,
  tipo_identificacao: string,
  canal_entrada: string,
  servico_slug: string,
  servico_titulo: string,
  setor_id: number,
  envolvidos: any[],
  tipo_manifestacao: number,
  orgao: string,
  assunto: number,
  local: string,
  estado: string,
  cidade: string,
  aceite: number,
): Promise<AxiosResponse> => apiouvidoria.post(
  'manifestacao-auth/',
  {
    conteudo,
    tipo_identificacao,
    canal_entrada,
    servico_slug,
    servico_titulo,
    setor_id,
    envolvidos,
    tipo_manifestacao,
    orgao,
    assunto,
    local,
    estado,
    cidade,
    aceite,
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
);

export const registerConfidentialManifestation = (
  conteudo: string,
  codigo_anonimo: string,
  email_sigiloso: string,
  cpf_sigiloso: string,
  nome_sigiloso: string,
  tipo_identificacao: string,
  servico_slug: string,
  servico_titulo: string,
  setor_id: number,
  estado: string,
  cidade: string,
  local: string,
  canal_entrada: string,
  envolvidos: any[],
  tipo_manifestacao: number,
  orgao: string,
  assunto: number,
): Promise<AxiosResponse> => apiouvidoria.post('manifestacao/', {
  conteudo,
  codigo_anonimo,
  email_sigiloso,
  cpf_sigiloso,
  nome_sigiloso,
  tipo_identificacao,
  servico_slug,
  servico_titulo,
  setor_id,
  estado,
  cidade,
  local,
  canal_entrada,
  envolvidos,
  tipo_manifestacao,
  orgao,
  assunto,
});

export const searchManifestation = (
  codigo: string,
  numero: string,
  email?: string,
  cpf?: string,
): Promise<AxiosResponse> => apiouvidoria.get('manifestacao/', {
  params: {
    codigo,
    numero,
    email,
    cpf,
  },
});

export const searchCep = (cep: string, cidade: string, uf?: string): Promise<AxiosResponse> => apiouvidoria.post('checar-cep/',
  {
    cep,
    cidade,
    uf,
  });

export const registerDemandAuth = (
  token: string,
  conteudo: string,
  tipo_identificacao: string,
  servico_slug: string,
  servico_titulo: string,
  servico_descricao: string,
  setor_id: number,
  canal_entrada: string,
  orgao: string,
  bairro: string,
  numero: string,
  cep: string,
  cidade: string,
  logradouro: string,
  estado: string,
  complemento: string,
  tempo_total: string,
  tipo_tempo: string,
  aceite: number,
):Promise<AxiosResponse> => apiouvidoria.post('demanda-auth/', {
  conteudo,
  tipo_identificacao,
  servico_slug,
  servico_titulo,
  servico_descricao,
  setor_id,
  canal_entrada,
  orgao,
  bairro,
  numero,
  cep,
  cidade,
  logradouro,
  estado,
  complemento,
  tempo_total,
  tipo_tempo,
  aceite,
},
{
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const registerDemand = (
  conteudo: string,
  codigo_anonimo: string,
  email_sigiloso: string,
  cpf_sigiloso: string,
  nome_sigiloso: string,
  tipo_identificacao: string,
  servico_slug: string,
  servico_titulo: string,
  servico_descricao: string,
  setor_id: number,
  canal_entrada: string,
  orgao: string,
  numero: string,
  bairro: string,
  cep: string,
  cidade: string,
  logradouro: string,
  estado: string,
  complemento: string,
  tempo_total: string,
  tipo_tempo: string,
): Promise<AxiosResponse> => apiouvidoria.post('demanda/', {
  conteudo,
  codigo_anonimo,
  email_sigiloso,
  cpf_sigiloso,
  nome_sigiloso,
  tipo_identificacao,
  servico_slug,
  servico_titulo,
  servico_descricao,
  setor_id,
  canal_entrada,
  numero,
  orgao,
  bairro,
  cep,
  cidade,
  logradouro,
  estado,
  complemento,
  tempo_total,
  tipo_tempo,
});

export const AttachDocumentDemand = (
  demanda: string,
  titulo: string,
  arquivo: File,
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('demanda', demanda);
  formData.append('titulo', titulo);
  formData.append('arquivo', arquivo);
  return apiouvidoria.post('anexos_demanda/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const AttachDocument = (
  id: number | string,
  titulo: string,
  arquivo: File,
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('titulo', titulo);
  formData.append('manifestacao', id as string);
  formData.append('arquivo', arquivo);
  return apiouvidoria.post('anexos_manifestacao/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const FAQOuvidoria = () : Promise<AxiosResponse> => apiouvidoria.get('faq/');

export const getTerms = (): Promise<AxiosResponse> => apiouvidoria.get('termos/');
