import React from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@material-ui/core';
import { MdDeleteOutline } from 'react-icons/md';

import useStyles from './styles';
import { SearchI } from '../../../../interfaces/service';

interface ContainerInfoActionProps {
  serviceSelected: SearchI;
  serviceHasSaved: boolean;
  handleRemoveServiceSelected: () => void;
}

export default function ContainerInfoAction({
  serviceSelected,
  serviceHasSaved,
  handleRemoveServiceSelected,
}: ContainerInfoActionProps):JSX.Element {
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.serviceSelectedTextTitle}>
        {serviceSelected?.categoria_nome}
      </Typography>
      <Box className={classes.boxNextOptions}>
        <Box>
          <Typography className={classes.serviceSelectedText}>
            {serviceSelected?.titulo}
          </Typography>
          <Typography className={classes.serviceSelectedSubText}>
            {serviceSelected?.orgao_nome}
          </Typography>
        </Box>
        {!serviceHasSaved && (
        <IconButton id="trash" onClick={handleRemoveServiceSelected}>
          <MdDeleteOutline
            className={classes.iconTrash}
          />
        </IconButton>
        )}
      </Box>
    </Box>
  );
}
