import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FaCalendarDay, FaHeadset } from 'react-icons/fa';
import { Alert } from '@material-ui/lab';
import { InfoOutlined } from '@material-ui/icons';

import useStyles from './styles';
import { SearchI } from '../../../../interfaces/service';

interface SchedulerTypeActionProps {
  serviceSelected: SearchI;
  handleSetServiceType: (value: 'online' | 'presencial' | null) => void;
  handleSetTypeAttendence: (value: 'Online') => void;
  handleNexStep: (value: 'next' | 'prev') => void;
}

export default function ScheduleTypeAction({
  serviceSelected,
  handleSetServiceType,
  handleSetTypeAttendence,
  handleNexStep,
}: SchedulerTypeActionProps): JSX.Element {
  const classes = useStyles();
  const pathEnv = process.env;
  return (
    <Box className={classes.contentTypeScheduler}>
      <Typography className={classes.textInfoScheduler}>
        Como irá ocorrer o atendimento?
      </Typography>
      <Box className={classes.schedules}>
        <Box
          id="online-att"
          onClick={() => {
            if (pathEnv.REACT_APP_AGENDAMENTO_ONLINE_ATIVO !== 'true') return;
            if (!serviceSelected?.online) return;
            handleSetServiceType('online');
            handleSetTypeAttendence('Online');
            handleNexStep('next');
          }}
          className={
          (serviceSelected?.online && pathEnv.REACT_APP_AGENDAMENTO_ONLINE_ATIVO) === 'true'
            ? classes.boxItem
            : classes.boxItemDisabled
          }
        >
          <FaHeadset className={classes.iconItem} />
          <Typography className={classes.textItem}>
            Atendimento Online
          </Typography>
        </Box>
        <Box
          id="presencial-att"
          onClick={() => {
            if (pathEnv.REACT_APP_AGENDAMENTO_PRESENCIAL_ATIVO !== 'true') return;
            if (!serviceSelected?.agendavel) return;
            handleSetServiceType('presencial');
            handleNexStep('next');
          }}
          className={
          (serviceSelected?.agendavel && pathEnv.REACT_APP_AGENDAMENTO_PRESENCIAL_ATIVO === 'true')
            ? classes.boxItem
            : classes.boxItemDisabled
          }
        >
          <FaCalendarDay className={classes.iconItem} />
          <Typography className={classes.textItem}>
            Atendimento Presencial
          </Typography>
        </Box>
      </Box>
      <Alert
        icon={<InfoOutlined className={classes.warnIcon} />}
        severity="warning"
      >
        <strong>Orientações: </strong>
        Mantenha seus dados atualizados
        para não deixar de receber alertas de seu agendamento.
      </Alert>
    </Box>
  );
}
