/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useRef } from 'react';

const refContext = createContext({
  refCategory: null,
  refService: null,
  refEstatistic: null,
  refAttendance: null,
  refNoticias: null,
  refInicio: null,
  refVideos: null,
  refRegistryType: null,
  scrollToRef: (ref:React.MutableRefObject<any>) => {},
  perfilUser: null,
});

export default refContext;
