import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY,
      dark: process.env.REACT_APP_COLOR_PRIMARY,
    },
    secondary: {
      main: process.env.REACT_APP_COLOR_SECONDARY,
    },
    text: {
      primary: '#333',
    },

  },
  overrides: {
    MuiStepIcon: {
      root: {
        '&$active': {
          color: process.env.REACT_APP_COLOR_SECONDARY,
        },
        '&$completed': {
          color: process.env.REACT_APP_COLOR_SECONDARY,
        },
      },
    },
    MuiStepLabel: {
      label: {
        '&$active': {
          color: process.env.REACT_APP_COLOR_SECONDARY,
        },
        '&$completed': {
          color: process.env.REACT_APP_COLOR_SECONDARY,
        },
      },
    },
    MuiButton: {
      outlined: {
        borderRadius: 0,
        padding: '8px 8px',
        textTransform: 'none',
      },
    },
  },
  typography: {
    body1: {
      fontFamily: 'Roboto',
      textTransform: 'none',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      textAlign: 'center',
      textShadow: '0px 6px 14px rgba(24, 39, 75, 0.12)',
    },
    h3: {
      fontFamily: 'Roboto',

    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: '#333333',
    },
    h5: {
      fontFamily: 'Roboto',

    },
    h6: {
      fontFamily: 'Roboto',
      // textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    subtitle1: {
      color: '#d3d3d3',
    },
  },
});

export default theme;
