import { lazy } from 'react';

const Home = lazy(() => import('./Home'))
const SeachService = lazy(() => import('./SearchService'))
const ServiceInfo = lazy(() => import('./ServiceInfo'))
const LoginCallback = lazy(() => import('./LoginCallback'))
const CategoyService = lazy(() => import('./CategoryService'))
const OrgansList = lazy(() => import('./OrgansList'))
const OrganDetail = lazy(() => import('./OrganDetail'))
const MySchedules = lazy(() => import('./MySchedules'))
const MyAttendences = lazy(() => import('./MyAttendences'))
const SchedulePrint = lazy(() => import('./SchedulePrint'))
const AttendanceOnlineChat = lazy(() => import('./AttendanceOnlineChat'))
const NotFound = lazy(() => import('./NotFound'))
const newDetail = lazy(() => import('./NewDetail'))
const CategoryList = lazy(() => import('./Categorias'))
const News = lazy(() => import('./News'))
const Workspace = lazy(() => import('./Workspace'))
const QuickAccess = lazy(() => import('./QuickAccess'))
const Terms = lazy(() => import('./Terms'))
const MyInformations = lazy(() => import('./MyInformations'))
const VideoList = lazy(() => import('./VideoList'))
const Accessibility = lazy(() => import('./Accessibility'))
const NewManifestation = lazy(() => import('./NewManifestation'))
const SearchManifestation = lazy(() => import('./SearchManifestation'))
const Onbudsman = lazy(() => import('./Onbudsman'))
const ServiceList = lazy(() => import('./ServiceList'))

export {
  // eslint-disable-next-line import/prefer-default-export
  Home,
  SeachService,
  ServiceInfo,
  LoginCallback,
  CategoyService,
  OrgansList,
  OrganDetail,
  MySchedules,
  MyAttendences,
  SchedulePrint,
  AttendanceOnlineChat,
  NotFound,
  News,
  newDetail,
  Workspace,
  QuickAccess,
  Terms,
  MyInformations,
  CategoryList,
  VideoList,
  Accessibility,
  NewManifestation,
  SearchManifestation,
  Onbudsman,
  ServiceList,
};
