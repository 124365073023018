/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box, CircularProgress, Dialog, IconButton,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, {
  useCallback,
  useContext, useEffect, useState,
} from 'react';
import { MdClose } from 'react-icons/md';
import colorContext from '../../Context/colorContext';
import { SearchI } from '../../Pages/SearchService';
import { getService, getServiceWithParams } from '../../services/servico';
import AttendenceOnline from '../AttendenceOnline';
import AttendencePresential from '../ShedulingPresential';
import getCookie from '../../utils/getCookies';
import ScheduleTypeAction from './components/ScheduleTypeAction';
import ContainerInfoAction from './components/ContainerInfoAction';
import { Identification } from '../Identification';
import SearchServiceSelect from './components/SearchServiceSelect';
import { DataProps } from '../AsyncSelect';
import TitleDialog from './components/TitleDialog';

interface Props {
  handleCloseModal: () => void;
  openModal: boolean;
}

export type ListPublic = Array<'Cidadão' | 'Empresa' | 'Servidor'>;

export default function QuickSchedule({
  handleCloseModal,
  openModal,
}: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    buttonClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#fff',
    },
    icon: {
      width: 17.5,
      height: 20,
      color: colors.textAccentColor,
    },
    content: {
      margin: '24px 32px',
    },
    divider: {
      border: '1px solid #D8D8E5',
    },
    contentTypeScheduler: {
      margin: '32px 32px 35px 32px',
    },
    containerLoading: {
      width: '100%',
      textAlign: 'center',
    },
  }));
  const classes = useStyles();
  const [searchService, setSearchService] = useState<DataProps | null>(null);
  const [serviceSelected, setServiceSelected] = useState<SearchI>();
  const [validateQueryService, setValidateQueryService] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceHasSaved, setServiceHasSaved] = useState<boolean>(false);
  const [serviceType, setServiceType] = useState<
  'online' | 'presencial' | null
  >();
  const [typeAttendence, setTypeAttendence] = useState<'Online'>();
  const [schedulingCompleted, setSchedulingCompleted] = useState<boolean>(false);

  const [userProfile, setUserProfile] = useState<'Cidadão' | 'Empresa' | null>(null);
  const [onlyPublic, setOnlyPublic] = useState<boolean>(false);
  const [cnpjNumber, setCnpjNumber] = useState<string | null>(null);
  const [companyCountry, setCompanyCountry] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [hasScheduled, setHasScheduled] = useState<boolean>(false);

  const onGetHasScheduler = useCallback(() => {
    setHasScheduled(!hasScheduled);
  }, [hasScheduled]);

  const handleGetServiceSelected = (service: SearchI) => {
    if (service) {
      setServiceSelected(service);
    } else {
      setServiceSelected(undefined);
    }
  };

  const handleSetService = (value: DataProps) => {
    setSearchService(value);
  };

  const handleRemoveServiceSelected = () => {
    setServiceSelected(undefined);
    setSearchService(null);
    setServiceType(null);
    setSchedulingCompleted(false);
    setCurrentStep(0);
    setCnpjNumber(null);
  };

  const handleNextStep = (type: 'next' | 'prev') => {
    setCurrentStep((oldState) => {
      if (type === 'next') {
        if (onlyPublic) {
          return 2;
        }

        return oldState + 1;
      }
      return oldState - 1;
    });
  };

  const handleChangeProfile = (current: 'Cidadão' | 'Empresa') => {
    setUserProfile(current);
  };

  const handleSearchService = async (search: string) => {
    setValidateQueryService(false);
    const tokenSiseci: string | null = getCookie(
      'gov_access_token_sso',
    );
    if (search.length > 2) {
      setValidateQueryService(true);
      const { data } = await getServiceWithParams(
        {
          ativo: true,
          page: 1,
          items_size: 10,
          publico_especifico: '',
          search,
        },
        tokenSiseci,
      );

      const responseFormatted = data?.results?.filter((service: SearchI) => (service.agendavel || service.online) === true)
        .map((serviceData: SearchI) => ({
          value: serviceData.slug,
          label: serviceData.titulo,
        }));

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return responseFormatted;
    }
  };

  const handleCheckPublic = (listPublic: ListPublic) => {
    if (listPublic?.length) {
      const countPublic = listPublic.filter((publ) => publ !== 'Cidadão').length;

      if (countPublic === 0) {
        setOnlyPublic(true);
      } else {
        setOnlyPublic(false);
      }
    }
  };

  const handleChangeCountry = useCallback((value: string) => {
    setCompanyCountry(value);
  }, []);

  const handleSetCNPJ = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 18) {
      setCnpjNumber(event.target.value);
    }
  }, []);

  const isVisibleButton = userProfile === 'Cidadão' || (userProfile === 'Empresa' && (typeof companyCountry) === 'string' && companyCountry === 'SALVADOR');

  useEffect(() => {
    async function handleGetService() {
      try {
        setLoading(true);
        const { data } = await getService(searchService.value);

        handleGetServiceSelected(data?.results[0]);
        handleCheckPublic(data?.results[0].publico_especifico);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (searchService) {
      handleGetService();
    }
  }, [searchService]);

  const contentScheduling = (service: SearchI, type: 'online' | 'presencial'): JSX.Element => {
    if (type === 'presencial') {
      return (
        <AttendencePresential
          handleClose={handleCloseModal}
          serviceSelected={service}
          hasDependente={service.dependente}
          hasSaved={setServiceHasSaved}
          hasCompleted={setSchedulingCompleted}
          handleRemoveService={handleRemoveServiceSelected}
          cnpjNumber={cnpjNumber}
          handleHasScheduler={onGetHasScheduler}
        />
      );
    }
    return (
      <AttendenceOnline
        type={typeAttendence}
        handleClose={handleCloseModal}
        serviceSelected={service}
        hasDependente={service.dependente}
        hasSaved={setServiceHasSaved}
        hasCompleted={setSchedulingCompleted}
        handleRemoveService={handleRemoveServiceSelected}
        cnpjNumber={cnpjNumber}
        handleHasScheduler={onGetHasScheduler}
      />
    );
  };

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={!hasScheduled && handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        {!hasScheduled && (
        <>
          <IconButton
            onClick={() => {
              !hasScheduled && handleCloseModal();
              handleRemoveServiceSelected();
            }}
            className={classes.buttonClose}
          >
            <MdClose className={classes.icon} />
          </IconButton>
        </>
        )}
        <div>
          {!schedulingCompleted && <TitleDialog />}
          <Box className={classes.content}>
            {loading && (
              <Box className={classes.containerLoading}>
                <CircularProgress
                  size={30}
                  color="primary"
                />
              </Box>
            )}
            {!serviceSelected && !loading && (
              <SearchServiceSelect
                handleSearchService={handleSearchService}
                handleSetService={handleSetService}
                searchService={searchService}
                validateQueryService={validateQueryService}
              />
            )}

            {serviceSelected && !loading && !schedulingCompleted && (
            <ContainerInfoAction
              serviceHasSaved={serviceHasSaved}
              serviceSelected={serviceSelected}
              handleRemoveServiceSelected={handleRemoveServiceSelected}
            />
            )}
          </Box>

          {!schedulingCompleted && <Box className={classes.divider} />}

          {serviceSelected && !serviceType && currentStep === 0 && (
            <ScheduleTypeAction
              serviceSelected={serviceSelected}
              handleSetServiceType={setServiceType}
              handleSetTypeAttendence={setTypeAttendence}
              handleNexStep={handleNextStep}
            />
          )}

          {serviceSelected && serviceType && currentStep === 1 && (
            <Identification.Root>
              <Identification.Header contentLogo={false} />
              <Identification.Content>
                <Identification.Option
                  controlRadio={handleChangeProfile}
                  profile={userProfile}
                  listOptions={serviceSelected.publico_especifico}
                  cnpjNumber={cnpjNumber}
                  handleChangeValueCnpj={handleSetCNPJ}
                  companyCountry={companyCountry}
                  onCompanyCountry={handleChangeCountry}
                />
              </Identification.Content>
              <Identification.ContentAction>
                <Identification.Action
                  handleSubmit={handleRemoveServiceSelected}
                  isDisabled={false}
                  text="VOLTAR"
                  variant="outlined"
                />
                <Identification.Action
                  handleSubmit={() => handleNextStep('next')}
                  isDisabled={false}
                  text="SELECIONAR"
                  variant="contained"
                  isVisible={isVisibleButton}
                />
              </Identification.ContentAction>
            </Identification.Root>
          )}

          {serviceSelected
            && serviceType
            && currentStep === 2
            && contentScheduling(serviceSelected, serviceType)}
        </div>
      </div>
    </Dialog>
  );
}
