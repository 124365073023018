/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Button,

} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import QrCode from 'qrcode.react';

import { useParams } from 'react-router-dom';
import { MdPrint } from 'react-icons/md';
import dateFormatToBR from '../../utils/dateFormat';
import { comprovanteAtendimento } from '../../services/atendimentos';
import { Scheduler } from '../../interfaces/schedulePrint';

// Create styles
const useStyles = makeStyles((theme: Theme) => ({
  boxHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: 20,
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textCpf: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'capitalize',
  },
  textNumberCounter: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',

  },
  textCounter: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textTansform: 'uppercase',
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'capitalize',
    color: '#737B7D',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  textService: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#373F41',
  },
  textOrgan: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',

  },
  textDateTime: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#373F41',
  },
  textTicket: {
    paddingTop: '20px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#373F41',
    paddingBottom: '20px',
  },
  btnPrint: {
    backgroundColor: '#3CD278',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',
    width: '60%',
    textTransform: 'capitalize',
    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00a04b',

    },
  },
  boxActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '25px',
  },
}));

// Create Document Component
export default function PrintFromCode(): JSX.Element {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [schedule, setSchedule] = useState<Scheduler>();
  const [error, setError] = useState<boolean>(false);

  const { ticket }: any = useParams();

  const formatDate = (date: string, type: 'onlyDate' | 'onlyOur' | 'all') => {
    const datePart = date.split('T');
    const datePartSplit = datePart[0].split('-');
    const hourPart = datePart[1].split(':');
    if (type === 'onlyDate') {
      return `${datePartSplit[2]}/${datePartSplit[1]}/${datePartSplit[0]}`;
    }
    if (type === 'onlyOur') {
      return `${hourPart[0]}:${hourPart[1]}`;
    }
    return `${datePartSplit[2]}/${datePartSplit[1]}/${datePartSplit[0]} ${hourPart[0]}:${hourPart[1]}`;
  };

  const getVoucherByTipe = async () => {
    try {
      setLoading(true);
      let result: any;
      if (ticket) {
        const { data } = await comprovanteAtendimento({
          ticket,
        });
        if (data?.results?.length) {
          result = data?.results?.[0];
          setError(false);
        } else {
          setError(true);
        }

        if (result) {
          setSchedule(result);
        }
      }
      setLoading(false);
    } catch (err) {
      setError(true);
      console.log(err);
      setLoading(false);
    }
  };

  const getDateShowedUpScheduler = useMemo(() => {
    if (!loading && ticket && schedule) {
      return schedule?.historico?.find((item) => item.status === 'Compareceu');
    }
  }, [loading, schedule, ticket]);

  useEffect(() => {
    const captcha = document.getElementsByClassName('grecaptcha-badge');
    if (captcha && captcha.length) {
      captcha[0].remove();
    }
  }, [schedule]);

  useEffect(() => {
    const enable = document.getElementsByClassName('enabled');
    if (enable && enable.length) {
      enable[0].remove();
    }
    if (ticket) {
      getVoucherByTipe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);
  useEffect(() => {
    if (!loading && ticket && schedule) {
      window.print();
    }
  }, [loading, ticket, schedule]);

  return (
    <Box style={{ padding: 20 }} id="print">

      {loading && (
        <Box
          marginBottom="60px"
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}

      {!loading && schedule && (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.boxHeader}
          >
            <Grid item>
              <Typography className={classes.textName}>{schedule?.user?.first_name || schedule?.nome}</Typography>
              <Typography className={classes.textCpf}>{schedule?.user?.username || schedule?.cpf}</Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item style={{ paddingBottom: '20px' }}>
              <Typography
                className={classes.textUnidade}
              >
                {schedule.unidade.nome.toLowerCase()}
              </Typography>
              <Typography
                className={classes.textService}
              >
                {schedule.servico.titulo}
              </Typography>

              <Typography
                className={classes.textOrgan}
              >
                {schedule?.orgao_responsavel && `Órgão: ${schedule.orgao_responsavel}`}
              </Typography>
              <Typography className={classes.textDateTime}>
                Protocolo:
                <Typography component="strong">
                  {' '}
                  {schedule?.protocolo}
                </Typography>
              </Typography>
              <Typography className={classes.textDateTime}>
                Data/Hora do agendamento:
                {' '}
                <Typography component="strong">
                  {formatDate(schedule.data_solicitacao, 'all')}
                </Typography>
              </Typography>
              {getDateShowedUpScheduler && (
              <Typography className={classes.textDateTime}>
                Data/Hora do Comparecimento:
                {' '}
                <Typography component="strong">
                  {formatDate(getDateShowedUpScheduler.created_at, 'all')}
                </Typography>
              </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-around"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              {schedule.hora_inicio && (
                <>
                  <Typography className={classes.textDateTime}>
                    Data/hora do Início do Atendimento:
                    <Typography component="strong">
                      {' '}
                      {schedule.hora_inicio && ` ${dateFormatToBR(schedule.hora_inicio, true)}`}
                    </Typography>
                  </Typography>
                </>
              )}
              {schedule.hora_fim && (
                <>
                  <Typography className={classes.textDateTime}>
                    Data/hora Final do Atendimento:
                    <Typography component="strong">
                      {' '}
                      {schedule.hora_fim && ` ${dateFormatToBR(schedule.hora_fim, true)}`}
                    </Typography>
                  </Typography>
                </>
              )}

              <Typography className={classes.textDateTime} style={{ marginBottom: 20 }}>
                Dependente:
                {' '}
                <Typography component="strong">{schedule.dependentes ? 'Sim' : 'Não'}</Typography>
                {schedule.dependentes && (
                <>
                  <Typography className={classes.textDateTime}>
                    Nome:
                    {' '}
                    <Typography component="strong">{schedule.dependentes.nome}</Typography>
                  </Typography>
                  <Typography className={classes.textDateTime}>
                    CPF:
                    {' '}
                    <Typography component="strong">{schedule.dependentes.cpf}</Typography>
                  </Typography>
                  <Typography className={classes.textDateTime}>
                    Grau de parentesco:
                    {' '}
                    <Typography component="strong">{schedule.dependentes.grau_parentesco}</Typography>
                  </Typography>
                </>
                )}
              </Typography>

              <Typography className={classes.textDateTime}>
                Status do atendimento:
                <Typography component="strong">
                  {' '}
                  {schedule.status}
                </Typography>
              </Typography>
              <Typography className={classes.textTicket}>{schedule.ticket}</Typography>
              <Typography className={classes.textDateTime}>
                Requisitos:
              </Typography>
              <Box component="div" dangerouslySetInnerHTML={{ __html: schedule?.servico?.requisitos }} />
            </Grid>
            <Grid item xs={4}>
              <QrCode value={`${process.env.REACT_APP_URL}/comprovante/agendamento/${ticket}`} size={180} />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>

              <Button variant="contained" color="primary" className="print_none" onClick={() => window.print()}>
                <style>
                  {'@media print {.print_none{display: none;}}'}
                </style>
                {' '}
                <MdPrint />
                {' '}
                <Typography>IMPRIMIR</Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {error && (<Typography className={classes.textName}>Atendimento/Agendamento não localizado!!</Typography>)}
    </Box>
  );
}
